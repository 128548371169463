import { render, staticRenderFns } from "./wmsTemplateStyleAdd.vue?vue&type=template&id=0b87d846&scoped=true&"
import script from "./wmsTemplateStyleAdd.vue?vue&type=script&lang=js&"
export * from "./wmsTemplateStyleAdd.vue?vue&type=script&lang=js&"
import style0 from "./wmsTemplateStyleAdd.vue?vue&type=style&index=0&id=0b87d846&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "0b87d846",
  null
  
)

export default component.exports